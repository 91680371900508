import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Lista, { przeliczTrase } from './lista';
import ConfirmDialog from './yesnodialog';
import { useState } from "react";
import { timeConvert } from './SelectController';
import { calculate } from './SelectController';
import {Wydruk} from './wydruk';


// pomocnicza fukcja do animacji dialogu
    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
        children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="down" ref={ref} {...props} />;
    });
  
  // komponent buttona z dialogiem do wyswietlenia trasy
const PropsRoute = (props) => {
    const [open, setOpen] = React.useState(false);
    const [yesnoOpen, OpenYesno] = useState(false);
    const [totals, setTotals] = useState([0,0]);
    const total = () => {
      var d=0;
      var t=0;
      setTotals([d,t]);
      props.waypoints.map(( {name, nkdg, declination,distance},index,array ) => {
        var ret=calculate(name,index>0?array[index-1].declination:declination,nkdg,distance,props.wind,props.speed);
        d+=ret.distance?Number(ret.distance):0;
        d=Math.round(d*100)/100;
        t+=ret.hours?Number(ret.hours):0;
        if ( d && t ){
          setTotals([d,t]);
        }
        return true;
        //console.log(ret.distance);
      })
    };

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleClear = () => {
        OpenYesno(true);
        setOpen(false);
      };

    const handleRevert = () => {
        let wpt = props.waypoints.reverse();
        let wpts=przeliczTrase(wpt);
        
        props.setWpt(wpts);
        //setOpen(false);
      };

    const usunTrase = () => {
      props.setWpt([]);
      setOpen(false);
    }

    React.useEffect( ()=> {
      total();
    },[props.waypoints, props.wind, props.speed])

    return (
      <div>
        {props.waypoints.length>0?
        <div className='button-trasa'>
            <Button color="secondary" variant="contained" size="small" onClick={handleClickOpen}  className="trasa-button MuiButton-contained-modified">
              {props.waypoints.length>0?(props.waypoints[0].name?props.waypoints[0].name:"..."):"Brak"}
              {props.waypoints?(props.waypoints.length>2?String.fromCharCode(8594)+"["+ (props.waypoints.length-2) + "]":""):""}
              {props.waypoints?(props.waypoints.length>1?(String.fromCharCode(8594)+props.waypoints[props.waypoints.length-1].name):""):""}
            </Button>
            <Dialog
              open={open}
              PaperProps={{ sx: { mt: "0px", mx: "0px"} }}
              scroll="paper"
              //fullScreen={fullScreen}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle className='trasa-title'>Trasa<div className='trasa-title-total'>{totals[0]} NM czas: {timeConvert(totals[1])}</div></DialogTitle>
              <DialogContent>
                <Lista wpts={props.waypoints} setWpt={props.setWpt} distance={props.distance} wind={props.wind} speed={props.speed} totals={totals} setTotals={setTotals}/>
              </DialogContent>
              <DialogActions>
                {props.waypoints.length>0
                ? <div>
                  <Wydruk wpts={props.waypoints} wind={props.wind} speed={props.speed}  disabled={ props.waypoints.length<2} pogoda={props.pogoda}/>
                  <Button onClick={handleRevert} className='MuiButton-dialog-modified' disabled={ props.waypoints.length<2 }>Odwróć</Button>
                  <Button onClick={handleClear} className='MuiButton-dialog-modified'disabled={ props.waypoints.length<2}>Wyczysc</Button>
                  </div>
                :<div></div>}
                <Button onClick={handleClose}>Zamknij</Button>
              </DialogActions>
            </Dialog>
          </div>
          :<div></div>
          }
        <ConfirmDialog title={<div>Uwaga</div>} open={yesnoOpen} children={<div>Czy chcesz usunać trasę?</div>} setOpen={OpenYesno} onConfirm={usunTrase}/>
      </div>
    );
  }

  export default PropsRoute;