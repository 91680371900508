import "leaflet/dist/leaflet.css";
import { Polygon,Tooltip, Circle } from "react-leaflet";
import uuid from 'react-uuid';
import { useState, useEffect } from "react";


const Area = (props) => {
    const [visibleAreas, setVisibleAreas]=useState([]); // tablica do wyswietlenia
    const [strefyWczytane,setStrefyWczytane]=useState([]);
    
    const altitude = props.altitude;
    const time = props.time;
    const strefy=["CTR","ATZ","TMA","NRA","R","P","FIS"];
    //const strefy=["CTR","MCTR","ATZ","NRA","R"]

    //function count( area ){
    //    var name=area.name.split(" ",1 )[0];
    //    types[name]=types[name]!=null?types[name]+1:1; 
    //}

    function timeMatches( aup ){
        //return true;
            let data=(new Date()).toISOString().substring(0, 10) + " ";
           
            let strefaStart=new Date(data+aup.tmin);
            let strefaStop=new Date(data+aup.tmax);
            if ( strefaStop<=strefaStart ) strefaStop.setDate(strefaStop.getDate() + 1);
            let flStart=new Date(data+time[0]);
            let flStop=new Date(data+time[1]);
            /*
            console.log( "start strefy: " + strefaStart);
            console.log( "stop strefy: " + strefaStop);
            console.log( "start: " + flStart);
            console.log( "stop: " + flStop);
            */
            if ( flStart>strefaStop ||  flStop<strefaStart ) 
                return false;
            if ( altitude[0]>=aup.hmax || altitude[1]<=aup.hmin)
                return false;
            return true;
    }

    function filtrujAup( area ){
    /* tu filtrujemy co chcemy zawsze wyswietlic */
        if ( strefy.some(s=>( s===area.type ) ) )
            if ( altitude[1]>=area.hmin && altitude[0]<=area.hmax)
                return true;
    /* tu filturujemy strefy z AUP*/
        if (props.aup.some( aup => ( area.name.includes(aup.name) && timeMatches(aup) ) ) )
            return true;
    }

    //funkcja do porownania nazwy strefy z AUP i strefy z tablicy
    //var types={};
    

    // sprawdzmy ile mamy jakich stref w poszczegolnych kategoriach ( TRA/TSA....)
    //props.dane.map( area => (
    // count(area)
    //));
    //console.log( types );

    useEffect(() => {
        
        /* 
        function sprawdzStrefy(){
            function checkName( area, aup ){
                if ( area.name.startsWith(aup.name)) return true;
                return false;
            }
            function findInAreas( aup ) {
                
                if ( !props.dane.some( area=> ( 
                    checkName(area, aup)
                    ) ) ){
                    console.log("Nie znalazlem:" + aup.name);
                }
            }
            props.aup.map( aup => ( findInAreas(aup) )
            )
        }
        
        console.log('sssss');
        sprawdzStrefy();
        */
        /* tu poczatek od zonez bierzemy je z:
        https://dronemap.pansa.pl/api/zone/
        lub, ale to jeszcze nie obsluzone
        https://airspace.pansa.pl/map-configuration/static-airspace 
        */
       /*
        let d =(props.dane.map( d=>{ d.hmin=d.min;d.hmax=d.max;d.vertices=d.geojson.coordinates.map( d=>{ let ret=d.map( p=>{return[p[1],p[0]]}); return ret;});  return d;}));
        console.log(d);
        setStrefyWczytane(d);
        */
        let as=strefyWczytane.filter( airspace => ( filtrujAup( airspace) ) );
        /*tu koniec, odhaszuj ponizej zeby przetwarzac openflightmaps strefy-1.xml*/
        //let as=props.dane.filter( airspace => ( filtrujAup( airspace) ) );
        as.map( ( space, i ) => {
            const found = props.aup.find((aup) => space.name.includes(aup.name));
            if (found) {
                as[i].czasy= "Strefa zajęta od " + found.tmin + " do " + found.tmax + " UTC"
                as[i].wysokosci="Granice pionowe " + found.hmin + " - " + found.hmax;
            }
        })
        setVisibleAreas(as);
    }, [props.aup, strefyWczytane, props.altitude, props.time]);

    useEffect(() => {
        /* tu poczatek od zonez bierzemy je z:
        https://dronemap.pansa.pl/api/zone/
        */
       
        let d=props.dane;
        if ( d.length>0 && d[0].geojson ){ // konwertujemy z drone radar, bo tam lat z on jest zamienione miejscami
            d =(props.dane.map( d=>{ d.hmin=d.min;d.hmax=d.max;d.vertices=d.geojson.coordinates.map( d=>{ let ret=d.map( p=>{return[p[1],p[0]]}); return ret;});  return d;}));
            d=d.filter( d=>(!d.type.includes("DRA") && !d.type.includes("RPA")) ) // wyrzucamy strefy dronowe
        }
        //console.log(d);
        setStrefyWczytane(d);
    },[props.dane]);

    function getStrokeColor( area ){

        if ( area.name.includes( "MRT") ){
            return "darkgrey";
        }


        if ( area.name.includes( "RMZ") ){
            return "#e9f0ec";
        }

        if (  area.type=== "NRA" || area.type=== "R" ||area.type=== "TSA"||area.type=== "D" ){
            return "#f7052d";
        }

        if ( area.type=== "CTR"||area.type=== "`MCTR"|| area.type=== "ATZ" ){
            return "#0000FF";
        }
        if ( area.type=== "TMA"|| area.type=== "MTMA" )
        {
            return "#0000AF"
        }

        if ( area.type=== "ATZ"|| area.type=== "TRA"){
            return "#0e7526";
        }

        return "grey";

    }

    function showAreas(){
        //console.log( visibleAreas )
        return (
            visibleAreas.map( area => (
                (!area.radius || area.radius===null || area.radius==='' || area.center===null )?
                <Polygon positions={area.vertices} 
                color={getStrokeColor(area)}
                strokeOpacity={0.9}
                weight={1} 
                fillColor={getStrokeColor(area)}
                fillOpacity={0.20}
                selectable = {false}
                key={uuid()}
                >
                    <Tooltip > 
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>{area.name}</span>
                            <span>{area.czasy}</span>
                            <span>{area.wysokosci}</span>
                        </div>
                    </Tooltip>
                </Polygon>
                :
                <Circle 
                center={area.center} 
                radius={area.radius}
                color={getStrokeColor(area)}
                strokeOpacity={0.9}
                weight={1} 
                selectable = {false}
                fillColor={getStrokeColor(area)}
                fillOpacity={0.20}
                key={uuid()}
                >
                    <Tooltip>
                       <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>{area.name}</span>
                            <span>{area.czasy}</span>
                            <span>{area.wysokosci}</span>
                        </div>
                    </Tooltip>
                </Circle>
                
        ) )
        )
    }

    return (
        <div>
            {
            showAreas()
            }
      
        </div>
    )

} 
export default Area;