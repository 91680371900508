import greatCircle from "@turf/great-circle";
import { useState, useEffect } from "react";
import { GeoJSON } from "react-leaflet";
import { Marker } from "react-leaflet";

import uuid from "react-uuid";
const myStyle = {
    "color": "#ff2200",
    "weight": 3,
    
};

const  Trasa = (props) => {
    const [route,setRoute] = useState([]);
    useEffect( () => {
        function rysujTrase(){
            let route = [];
            props.wpts.map( (wpt,index,array) => {
                if ( index+1<array.length ){ // sprawdzamy czy jest nastepny punkt
                const start ={ lon:wpt.lon, lat:wpt.lat};
                const end ={ lon:array[index+1].lon, lat:array[index+1].lat};
                var gc=greatCircle([start.lon,start.lat],[end.lon,end.lat]);
                route=([...route, gc]);
                }
            return 1;
            })
            setRoute(route);
        }
        rysujTrase();
        
        },[props.wpts]);

    function markeryPZ(){
        return (
        props.wpts.map(  wpt => (
            <Marker position={[wpt.lat,wpt.lon]}
            key={uuid()}
            id={wpt.name}
            title={wpt.name}
            //icon={wpt}
            eventHandlers={{
            click: (e) => {
                console.log(e);
                console.log('marker ' + e + 'clicked')
                },
            }}
            ></Marker>
        ))
        )
    }

    useEffect(()=>{
        const GEOCODE_URL = "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location=";
        //console.log("Przeliczam trase");
        const Timeout = (time) => {
            let controller = new AbortController();
            setTimeout(() => controller.abort(), time * 1000);
            return controller;
        };

        async function reverseGeoCoding(wpt,index) {
            //console.log( wpts );
            let error=false;
                ( await fetch(GEOCODE_URL+`${wpt.lon},${wpt.lat}`,{signal: Timeout(2).signal})
                .then((resp) => resp.json())
                .then((resp) => {
                    //console.log(resp);
                        wpt.name  = resp.address.City!==""?resp.address.City:wpt.name;
                        //wpt.name = resp.address.Subregion+", "+resp.address.ShortLabel;
                        wpt.desc = resp.address.LongLabel;
                        wpt.geolocated = true;
                })
                .catch((err) => {console.log("Błąd czytania rev geoloc " + err);error=true;})
                )
                //console.log("setting " + wpt.name)
            let newArr = [...props.wpts]; 
            newArr[index] = wpt; 
            if (!error)
            props.setWpt(newArr);
        }


        const DEVIATION = "https://www.ngdc.noaa.gov/geomag-web/calculators/calculateDeclination?browserRequest=true&magneticComponent=d&key=zNEw7&model=WMM&resultFormat=json&ajax=true&"

        async function getDeviation(wpt,index) {
            //console.log( wpts );
            let error=false;
            //&lat1=53.12&lat1Hemisphere=N&lon1=23.13&lon1Hemisphere=E
            let rest="lat1="+Math.abs(wpt.lat)+"&lat1Hemisphere="+(wpt.lat>0?"N":"S")+"&lon1="+Math.abs(wpt.lon)+"&lon1Hemisphere="+(wpt.lon>0?"E":"W");

                ( await fetch(DEVIATION+rest,{signal: Timeout(40).signal})
                .then((resp) => resp.json())
                .then((resp) => {
                    //console.log(resp);
                        wpt.declination=resp.result[0].declination;
                })
                .catch((err) => {console.log("Błąd czytania deviacji " + err);error=true;})
                )
            let newArr = [...props.wpts]; 
            newArr[index] = wpt; 
            if (!error)
            props.setWpt(newArr); // to absolutnie ??? do przerobki, on zmienia w kazdym odczytaniu,(tam jest map ) powinien na koniec
        }

        //console.log( props.wpts);
        props.wpts.map( (wpt,index) =>{
            if ( wpt.geolocated !== true ){
                reverseGeoCoding(wpt,index);
            }
            if ( !wpt.declination ){
                getDeviation(wpt,index);  // to absolutnie ??? do przerobki, on zmienia w kazdym odczytaniu, powinien na koniec
            }
            
            return true;
        })
        //console.log(props);

    },[props.wpts]);

    return(
        <div id="trasa">
        <GeoJSON key={ uuid() } data={route} style={myStyle}/>
        {markeryPZ()}
        </div>
    )
}

export default Trasa;