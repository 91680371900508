export const calcWind = ( wpts, setWindDir, setWindSpd, setWind ) => {
    let maxLon=-1;
    let minLon=-1;
    let maxLat=-1;
    let minLat=-1;
    let medLat=52.16;
    let medLon=19.56;

    if ( wpts.length ){       
        wpts.map( wpt => {
            if (wpt.lon<minLon || minLon===-1)
                minLon=Number(wpt.lon);
            if (wpt.lat<minLat || minLat===-1)
                minLat=Number(wpt.lat);
            if (wpt.lon>maxLon || maxLon===-1)
                maxLon=Number(wpt.lon);
            if (wpt.lat>maxLat || maxLat===-1)
                maxLat=Number(wpt.lat);
            return true;
        });
        medLat=(maxLat+minLat)/2;
        medLon=(maxLon+minLon)/2;
    }
    
    //console.log([medLat,medLon]);
    return getOpenWeather(medLat,medLon, setWindDir, setWindSpd, setWind);
/*
    const getWinds = (coord) =>{
        const GEOCODE_URL = "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location=";

        const Timeout = (time) => {
            let controller = new AbortController();
            setTimeout(() => controller.abort(), time * 1000);
            return controller;
        };

        async function reverseGeoCoding(wpt,index) {
            //console.log( wpts );
            let error=false;
                ( await fetch(GEOCODE_URL+`${wpt.lon},${wpt.lat}`,{signal: Timeout(2).signal})
                .then((resp) => resp.json())
                .then((resp) => {
                    //console.log(resp);
                        wpt.name  = resp.address.City!==""?resp.address.City:wpt.name;
                        //wpt.name = resp.address.Subregion+", "+resp.address.ShortLabel;
                        wpt.desc = resp.address.LongLabel;
                        wpt.geolocated = true;
                })
                .catch((err) => {console.log("Błąd czytania rev geoloc " + err);error=true;})
                )
                //console.log("setting " + wpt.name)
            let newArr = [...props.wpts]; 
            newArr[index] = wpt; 
            if (!error)
            props.setWpt(newArr);
    }
    }
    */

    
}


const getOpenWeather = async (lat,lon, setWindDir, setWindSpd, setWind) => {
    let now=new Date();
    try{
        const response = await fetch('https://api.open-meteo.com/v1/forecast?latitude='+lat+'&longitude='+lon+'&hourly=windspeed_180m,winddirection_180m&windspeed_unit=kn');
        const res= await response.json();
        //console.log(res);
        let dir=res.hourly.winddirection_180m[now.getUTCHours()];
        let wind=res.hourly.windspeed_180m[now.getUTCHours()];
        setWindDir(dir);
        setWindSpd(Math.round(wind));
        setWind([dir,Math.round(wind)]);
    }catch(err) {
        console.log('something went wrong', err);
    };
}
