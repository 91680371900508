import React from "react";
import { useState, useEffect } from "react";
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import { Browser } from "leaflet";
import Control from "react-leaflet-custom-control";
import { ButtonGroup, Button, Tooltip } from "@mui/material";
import 'leaflet/dist/leaflet.css'
import Przestrzen from './przestrzen.js'
import Alert from '@mui/material/Alert';
import LoadingSpinner from "./LoadingSpinner.js";
import AlertTitle from '@mui/material/AlertTitle';
import MouseCoordinates from "./LeafletCoordinates.js";
import Area from './Area.js'
import Box from '@mui/material/Box';
import ClickControl from "./SelectController.js";
import Trasa from "./trasa.js";
import PropsRoute from "./PropsRoute.js";
import FltProp from "./FltProp.js";
import PropsWind from "./PropsWind.js";


const Mapa = () => {
  const now = new Date();
  const host="https://aero.nets.com.pl"
  const [lotniska, setData] = useState([]);
  const [strefy, setAirspaces] = useState([]);
  const [aup, setAup] = useState([]);
  const [error, setErrorMessage] = useState(null);
  const [selected,setSelected] = useState([true,true,true,false]);
  const [alt,setAltitude] = useState([0,2000]);
  const [time,setTime] = useState([now.getUTCHours(),20]);
  const [wind,setWind] = useState( [0,0] );
  const [autoWind,setAutoWind] = useState( false );
  const [waypoints,setWpt] = useState([]);
  const [distance,setDistance] = useState([]);
  const [speed,setSpeed] = useState([85]);
  const [gloc,setGlock]= useState([[[52.59,14.61],[53.92,14.34],[54.87,20.03],[52.58,19.01],[53.49,17.04],[52.59,14.61]],
               [[52.59,14.61],[53.49,17.04],[53.49,17.04],[52.58,19.01],[50.95,18.59],[50.83,14.85],[52.59,14.61]],
               [[52.10,23.76],[52.18,21.20],[52.58,19.01],[54.87,20.03],[54.40,23.55],[52.10,23.76]],
               [[49.93,23.14],[50.65,24.51],[52.10,23.76],[52.18,21.20],[52.58,19.01],[50.95,18.59],[49.93,23.14]],
               [[50.83,14.85],[50.95,18.59],[50.94,21.14],[49.93,23.14],[49.01,22.89],[49.20,19.81],[50.83,14.85]],
              ]);
  const [pogoda,setPogoda] = useState({});
  
  const [spinner, setSpinner] = useState(false);    // spinner ladowania danych

  const myAPIKey = '71a7c9831201444585b447a665cef721';
    const isRetina = Browser.retina;
    var baseUrl = `https://maps.geoapify.com/v1/tile/osm-bright/{z}/{x}/{y}.png?apiKey=${myAPIKey}`;
    var retinaUrl = `https://maps.geoapify.com/v1/tile/osm-bright/{z}/{x}/{y}@2x.png?apiKey=${myAPIKey}`;



  const handleClick = (name) => {
    switch(name){
      case 'airport':
        setSelected( [!selected[0], selected[1],selected[2],selected[3]] );
        break;
      case 'airfield':
        setSelected( [selected[0], !selected[1],selected[2],selected[3]] );
        break;
      case 'vfr':
        setSelected( [selected[0], selected[1],!selected[2],selected[3]] );
        break;
      case 'ifr':
        setSelected( [selected[0], selected[1],selected[2],!selected[3]] );
        break;
      default:
        break;
    }
  };


  useEffect(() => {
    const dataFetch = async () => {
        setSpinner( true );
        await fetch(host+"/get-spaces.php?trawniki").then((res) => res.json())
        .then((d) => { setData(d); } )
        .catch(() => {
          setErrorMessage("Błąd importu danych punktów nawigacyjnych i lotnisk.");
          //setSpinner(false);
        })
          
    };
    dataFetch();
  },[]);

  useEffect(() => {
    const airspaceFetch = async () => {
        setSpinner( true );
        await fetch(host+"/get-spaces.php?areas").then((res) => res.json())
        .then((d) => { setAirspaces(d); setSpinner(false);} )
        .catch(() => {
          setErrorMessage("Błąd importu stref.");
          setSpinner(false);
        })
          
    };
    airspaceFetch();
  },[]);

  useEffect(() => {
    const aupFetch = async () => {
        setSpinner( true );
        await fetch(host+"/get-spaces.php?aup=0").then((res) => res.json())
        .then((d) => { setAup(d); } )
        .catch(() => {
          setErrorMessage("Błąd importu aup.");
          //setSpinner(false);
        })
    };
    aupFetch();
  },[]);

  useEffect(() => {
    const gametFetch = async () => {
        await fetch(host+"/get-spaces.php?gamet=1").then((res) => res.text())
        .then((d) => { 
          var gamet=[];
          var metar=[];
        gamet[0]=d.substring(d.indexOf('flightdataGAMET[0]=')+20,d.indexOf(';') ).replaceAll("\\n\\n","<BR>").replaceAll("\\n","<BR>");
        gamet[1]=d.substring(d.indexOf('flightdataGAMET[1]=')+20,d.indexOf(';',d.indexOf('flightdataGAMET[1]=')) ).replaceAll("\\n\\n","<BR>").replaceAll("\\n","<BR>");
        gamet[2]=d.substring(d.indexOf('flightdataGAMET[2]=')+20,d.indexOf(';',d.indexOf('flightdataGAMET[2]=')) ).replaceAll("\\n\\n","<BR>").replaceAll("\\n","<BR>");
        gamet[3]=d.substring(d.indexOf('flightdataGAMET[3]=')+20,d.indexOf(';',d.indexOf('flightdataGAMET[3]=')) ).replaceAll("\\n\\n","<BR>").replaceAll("\\n","<BR>");
        gamet[4]=d.substring(d.indexOf('flightdataGAMET[4]=')+20,d.indexOf(';',d.indexOf('flightdataGAMET[4]=')) ).replaceAll("\\n\\n","<BR>").replaceAll("\\n","<BR>");
        metar[0]=d.substring(d.indexOf('fmetar10=')+10,d.indexOf(';',d.indexOf('fmetar10=')) ).replace("\n","<BR>");
        metar[1]=d.substring(d.indexOf('fmetar05=')+10,d.indexOf(';',d.indexOf('fmetar05=')) ).replace("\n","<BR>");
        var taf=[];
        taf[0]=d.substring(d.indexOf('ftaffc=')+8,d.indexOf(';',d.indexOf('ftaffc=')));
        taf[1]=d.substring(d.indexOf('ftafft=')+8,d.indexOf(';',d.indexOf('ftafft=')));
        
        var glock=[];
        gloc.map( gnumber => {
          var points=[];
          gnumber.map( punkt => {
            points=[...points,[punkt[1],punkt[0]]];
          })
          glock=[...glock,[points]];
        })
        setGlock(glock);
        const pogoda={ gamet:gamet, taf: taf, metar:metar, gloc:gloc };
        setPogoda(pogoda);
        
        } )
        .catch((e) => {
          setErrorMessage("Błąd importu aup." +e );
          //setSpinner(false);
        })
    };
    gametFetch();
  },[]);


  return (
    <div >
    {
      error ? 
      <Alert variant="filled" severity="error">
        <AlertTitle>Błąd komunikacji</AlertTitle>
        <strong>{error}</strong>
      </Alert>
       : <></>
    }  
      <div 
          //className="control"> 
          className="box"> 
          {spinner && <LoadingSpinner /> }
          <Box 
          className="row header respbox"
          //className="control"
            border="solid 1px"
            >
              <PropsWind wind={wind} setWind={setWind} autoWind={autoWind} setAutoWind={setAutoWind} speed={speed} setSpeed={setSpeed} wpts={waypoints}/>
              <PropsRoute  waypoints={waypoints} distance={distance} setWpt={setWpt} wind={wind} speed={speed} pogoda={pogoda}></PropsRoute>
              <FltProp alt={alt} time={time} setAltitude={setAltitude} setTime={setTime}></FltProp> 
            {/*
            <AppControl className="row header" alt={alt} setAltitude={setAltitude} time={time} setTime={setTime} waypoints={waypoints} setWpt={setWpt}/>
            */}
          </Box>
          
          <MapContainer
          //className="row content"
          className="markercluster-map row content"
          center={[52.14, 18.17]}
          zoom={7}
          maxZoom={18}
          
          >
            <LayersControl position="topright">
              <LayersControl.BaseLayer checked name="OpenStreetMap">
                <TileLayer
                  //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  url={isRetina?retinaUrl:baseUrl}
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
                  id='osm-bright'
                />
              
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="ESRI Sattelite ">
                <TileLayer
                  url="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  
                  attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Sattelite ">
                <TileLayer
                  url="https://mt.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                  attribution="Tiles &copy; Google"
                />
              </LayersControl.BaseLayer>
            </LayersControl>
            <Control position="topright">
              <ButtonGroup orientation="vertical" variant="contained">
                <Tooltip placement="left" title="Lotniska">
                  <Button
                    color={selected[0] === true ? "primary" : "inherit"}
                    onClick={() => handleClick("airport")}
                    variant="contained"
                  >
                    D
                  </Button>
                </Tooltip>
                <Tooltip placement="left" title="Lądowiska">
                  <Button
                    color={selected[1] === true ? "primary" : "inherit"}
                    onClick={() => handleClick("airfield")}
                    variant="contained"
                  >
                    M
                  </Button>
                </Tooltip>
              </ButtonGroup>
              </Control>
              <Control position="topright">
              <ButtonGroup orientation="vertical" variant="contained">
                <Tooltip placement="left" title="Punkty VFR">
                  <Button
                    color={selected[2] === true ? "primary" : "inherit"}
                    onClick={() => handleClick("vfr")}
                    variant="contained"
                  >
                    V
                  </Button>
                </Tooltip>
                <Tooltip placement="left" title="Punkty IFR">
                  <Button
                    color={selected[3] === true ? "primary" : "inherit"}
                    onClick={() => handleClick("ifr")}
                    variant="contained"
                  >
                    I
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Control>
            
            <Przestrzen data={lotniska} selected={selected} wpts={waypoints} setWpt={setWpt}/>  
            <Area dane={strefy} aup={aup} altitude={alt} time={[time[0]+":00",time[1]+":00"]}/>
            <Trasa wpts={waypoints} setWpt={setWpt} setDistance={setDistance} wind={wind} pogoda={pogoda}/>
            <div className="leaflet-bottom leaflet-left"> 
              <MouseCoordinates />
            </div>
            <ClickControl punkty={lotniska} setWpt={setWpt} wpt={waypoints}/>
            
          </MapContainer>
        </div>
        
    </div>
  );
}

export default Mapa;
