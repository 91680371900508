import React from "react";
import Mapa from "./Mapa.js";


const App = () => {
  
  return (
    <Mapa />
  );
}
export default App;
