import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import { useState } from "react";
import { calcWind } from './autoWind';


// pomocnicza fukcja do animacji dialogu
    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
        children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="down" ref={ref} {...props} />;
    });
  
  // komponent buttona z dialogiem do wyswietlenia trady

  export default function PropsWind(props) {
    const { wind, setWind, autoWind, setAutoWind, speed, setSpeed, wpts } = props;
    const [ windDir,setWindDir ] = useState(wind[0]);
    const [ windSpd,setWindSpd ] = useState(wind[1]);
    const [ open, setOpen ] = React.useState(false);
    
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleSlider = (event, newValue) => {
        //console.log(event);
        setWindDir(newValue) ;
        setWind([newValue,windSpd]);
      };
    
    const handleSpeedSlider = (event, newValue) => {
        //console.log(event);
        setSpeed(newValue) ;
        //setWind([windDir,newValue]);
      };
  
    const autoWindHandle = (e) =>{
        if (e.target.checked){
            //setWind([-1,0]);
            //console.log("auto");
            calcWind(wpts,setWindDir,setWindSpd, props.setWind);
            //console.log(w);
            setAutoWind(true);
        }
        else{
            setWind([windDir,windSpd]);
            setAutoWind(false);
            
        }
    }

    const handleChangeWindSpeed = (e) => {
      const newValue=Number(e.target.value);
        if ( newValue>=0 && newValue<speed ){
            //console.log(e.target.value,Number(speed));
            setWindSpd(e.target.value,Number(speed));
            setWind([windDir,e.target.value]);
        }
        //setWindSpd(e.target.value);
    }

    return (
      <div>
        <div className='button-wiatr'>
            <Button variant="contained" size="small" onClick={handleClickOpen}  className="button-wiatr MuiButton-contained-modified">
              Parametry
            </Button>
            <Dialog
              open={open}
              PaperProps={{ sx: { mt: "0px", mx: "0px"} }}
              scroll="paper"
              //fullScreen={fullScreen}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle>Parametry</DialogTitle>
              <DialogContent>
              <p>Prędkość samolotu: {speed} kts</p>
              <Slider
                   max={250}
                   min={30}
                   size="small"
                   value={speed}
                   step={1}
                   marks={true}
                   onChangeCommitted={handleSpeedSlider}
                   valueLabelDisplay="auto"
                   //disabled={autoWind}
                   //label="Kierunek"
                   //aria-label="Always visible"
                   className='left'
                />
              <FormControlLabel control={<Checkbox checked={autoWind} onChange={autoWindHandle}/>} label="Wiatr automatycznie" />
             <p>Kierunek wiatru: {windDir}{'\u00b0'}</p>
              <Slider
                   max={359}
                   min={0}
                   size="small"
                   value={windDir}
                   step={1}
                   marks={true}
                   onChange={handleSlider}
                   valueLabelDisplay="auto"
                   disabled={autoWind}
                   //label="Kierunek"
                   //aria-label="Always visible"
                   className='left'
                />
                <TextField
                    id="outlined-number"
                    label="Prędkość wiatru [kts]"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={windSpd}
                    onChange={(e)=> handleChangeWindSpeed(e)}
                    variant="filled"
                    size="small"
                    disabled={autoWind}
                    className='right'
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Zamknij</Button>
              </DialogActions>
            </Dialog>
          </div>
          {/*
        <ConfirmDialog title={<div>Uwaga</div>} open={yesnoOpen} children={<div>Czy chcesz usunać trasę?</div>} setOpen={OpenYesno} onConfirm={usunTrase}/>
        */}
      </div>
    );
  }