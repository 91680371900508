import { useMapEvents } from "react-leaflet";
import uuid from 'react-uuid';
import distance from "@turf/distance";
import bearing from "@turf/bearing";


export const calc = ( wpt1, wpt2 ) => {
  let dis=distance([wpt1.lon,wpt1.lat],[wpt2.lon,wpt2.lat]);
  let bear=bearing([wpt1.lon,wpt1.lat],[wpt2.lon,wpt2.lat])
  if ( bear<0 ) bear=360+bear;
  if ( bear>359 ) bear=bear-360;
  return [dis,bear];
}


export const timeConvert = (n) => {
  var num = n*60;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.floor(minutes);
  var seconds = (minutes-rminutes)*60;
  var rseconds= Math.round(seconds);
  return (rhours>0?(rhours + "h"):"") + rminutes + "'" + (rhours<2?(rseconds + "\""):"");
  }
  

export const calculate = (name,declination,nkdg,distance,wind,speed) => {
  let nkdm=nkdg-declination;
  distance=Math.round((((distance)/1.852)*100))/100; // przeliczamy na mile
  //obliczamy nawigacyjny kierunek wiatru
  let nkw=wind[0]+180-declination;
  const wspeed=Number(wind[1]);
  if ( nkw > 359) nkw=nkw-360;
  //oblicamy kat wiatru ( -180, 180 )
  let kw=nkw-nkdm;
  if ( kw>180 || kw<-180 ) kw=-(360-kw);
  
  const kz=Math.asin((wspeed*Math.sin(kw*Math.PI/180.0))/speed)*180/Math.PI
  const w=Number(speed)+wspeed*Math.cos(Math.PI*kw/180);
  const time=Number(distance)/w;
  var course=nkdm-kz;

  if ( course<0 ) course+=360;
  if ( course>359 ) course-=360;
 
  const ret = { course: Math.round(course)+'\u00b0', time:timeConvert(time), hours:time, distance:distance, gs: Math.round(w), timemin:time };
  return ret;
}

const  ClickControl = (props) => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        let nr= props.wpt?props.wpt.length+1:1;
        const wpt={
            name: "WPT " + nr,
            lon:lng,
            lat:lat,
            desc:'',
            geolocated:false,
            id : uuid()
        }
        if ( props.wpt.length>0 ){
            const prevWpt={
                lon:props.wpt[props.wpt.length-1].lon,
                lat:props.wpt[props.wpt.length-1].lat
            }
            let c=calc( prevWpt, wpt );
            wpt.distance=c[0];
            wpt.nkdg=c[1];
            if ( c[0]>1 ) {// co najmniej kilometr jeden od drugiego
                props.setWpt([...props.wpt,wpt]);
            }
        }else // dodajemy pierwszy waypoin  t
            props.setWpt([...props.wpt,wpt]);
    }
  });
    return null;
  }

  export default ClickControl;