import React, { useEffect, useState } from "react";
import { calculate } from "./SelectController";
import uuid from "react-uuid";
import { timeConvert } from "./SelectController";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import {point,polygon} from "@turf/helpers";



export const Npl = React.forwardRef( (props,ref) => {
    const [ trasa,setTrasa ] = useState([]);
    const { wpts, wind, speed } = props;
    const [ total, setTotals ] = useState([]);
    var kursy=[];
    const [ gamety, setGamet ] = useState([false,false,false,false,false]);
    const [ metary, setMetar ] = useState([]);


    useEffect( ()=>{
        var total=[0,0];
        var gamety=[false,false,false,false,false];
        var metary=[]
        wpts.map( (wpt, index, array) =>{
            if ( index>0 ){
                const start=array[index-1];
                const ret=calculate("",start.declination, wpt.nkdg, wpt.distance, wind, speed );
                //console.log(ret);
                kursy=[...kursy,ret];
                total[0]+=ret.distance;
                total[1]+=ret.timemin;
            }
            var pt = point([wpt.lat, wpt.lon]);
            
            props.pogoda.gloc.map( (p,index) => {
                var poly = polygon([p]);
                //console.log ( pt, poly );
                if ( booleanPointInPolygon(pt, poly) ){
                    
                    gamety[index]=true;
                }
            });
            setGamet(gamety);

            
            props.pogoda.metar.map( m=>{
                var pos=m.indexOf( "METAR " + wpt.name );
                if ( pos>=0 ){
                    var end=(m.indexOf("<br>",pos));
                    var metar=m.substring(pos, end);
                    //console.log(metar);
                    metary=[...metary,metar];
                }
            })

            props.pogoda.taf.map( m=>{
                var pos=m.indexOf( "TAF " + wpt.name );
                if ( pos>=0 ){
                    var end=(m.indexOf("<br>",pos));
                    var metar=m.substring(pos, end);
                    metary=[...metary,metar];
                }
            })
            setMetar(metary);
        })
        /*
        console.log(kursy);
        console.log(wpts);
        console.log(total);
        */
       
        setTrasa(kursy);
        setTotals(total);
    }, [wpts,wind,speed])

    const oblicz = () => {
        return(
            <div>
                <div className="wiatr">
                    <p>Wiatr kierunek:{wind[0]+'\u00b0'} @ {wind[1]} kts</p>
                    <p>Prędkość IAS:{speed} kts</p>
                </div>
                <div key={uuid()} className="liniatrasy">
                    <div className="title titleName">
                        NAZWA
                    </div>
                    <div className="title titleCourse">Kurs</div>
                    <div className="title titleTime">Czas</div>
                    <div className="title titleDistance">S</div>
                    <div className="title titleSpeed">W</div>
                    <div className="title titleSpeed">NKDM</div>
                </div>
            {wpts.map( ( wpt,index,array ) =>(
                <div key={uuid()} className={index/2!==Math.round(index/2)?"liniatrasy":"liniatrasy shaded"}>  {/*co druga podswielamy*/}
                    <div className="wptname">
                        <p>{wpt.name}</p><p>
                        {wpt.freq?<span>{wpt.freq}</span>:''}
                        {(wpt.rwdir && wpt.elev)?<span>RWY:{wpt.rwdir}/{parseInt(wpt.rwdir)+180}</span>:''}
                        {(wpt.elev)?<span>ELEV:{wpt.elev} ft.</span>:''}
                        </p>
                    </div>
                    <div className="wptCourse">{index<array.length-1 && trasa[index]?trasa[index].course:''}</div>
                    <div className="wptTime">{index<array.length-1 && trasa[index]?trasa[index].time:''}</div>
                    <div className="wptDistance">{index<array.length-1 && trasa[index]?Math.round(trasa[index].distance):''}</div>
                    <div className="wptSpeed">{index<array.length-1 && trasa[index]?(trasa[index].gs):''}</div>
                    <div className="wptSpeed">{index<array.length-1 && array[index+1].nkdg && array[index+1].declination?Math.round(array[index+1].nkdg-array[index+1].declination):''}</div>
                </div>
            ))}
                <div key={uuid()} className="liniatrasy">
                    <div className="title titleName">
                    </div>
                    <div className="title titleCourse"></div>
                    <div className="title titleTime" >{timeConvert(total[1])}</div>
                    <div className="title titleDistance" >{total[0]?Math.round(total[0]):"0"}</div>
                    <div className="title titleSpeed"></div>
                    <div className="title titleSpeed"></div>
                </div>
                <div key={uuid()} className="taf">
                    {
                        metary.map( m=>(
                            <div key={uuid()} className="taf">{m}</div>
                        ))
                    }
                </div>
            </div>
            )
    }
    
    return (
        <div ref={ref} className="npl">
            <div className="lewa" >
                <div className="odlot">Zgoda odlotowa:</div>
                <div className="trasa-p">{ oblicz()}</div>
            </div>
            <div className="prawa">
                <div className="gamet" key={uuid()}>
                    {gamety.map((g,index)=>(
                            g?<div key={uuid()} className="gamet" dangerouslySetInnerHTML={{ __html: props.pogoda.gamet[index]}}></div>:""
                    ))}
                </div>
            </div>
        </div>
      );
  })