import { useEffect, useState } from "react";
import Punkt from './punkt'
import uuid from 'react-uuid';
import MarkerClusterGroup from '@changey/react-leaflet-markercluster'
import 'leaflet/dist/leaflet.css'
import '@changey/react-leaflet-markercluster/dist/styles.min.css'



const Przestrzen = ( props ) => {
    const [dane, setData] = useState([]);
    
   

    
    useEffect(() => {
        setData(props.data);
        //console.log(props.data);
      /*
      const dataFetch = async () => {
       
       
        await (
          (await fetch("http://10.0.10.38:8000/get-spaces.php").then((res) => res.json())
          .then((d) => { setData(d); } ) ) )
          
      };
  
      dataFetch();
      console.log(dane );
      */
     //console.log(dane );
    }, [props.data]);

    function checkSpace( punkt ){
        if ( props.selected[0] )
            if ( punkt.style === "2" || punkt.style === "5" )
                return true;
        if ( props.selected[1] )
            if ( punkt.style === "3"  )
                return true;
        if ( props.selected[2] )
            if ( punkt.style === "1" )
                return true;
        if ( props.selected[3] )
            if ( punkt.style === "17" )
                return true;
        if ( punkt.style > 100 )
                return true;
    }
  
    return (
        <MarkerClusterGroup>
        {
            dane.length >0 && 
            //dane.filter(punkt => punkt.style!=="5" || punkt.style!=="2"|| punkt.style!=="3"|| punkt.style!=="1"||punkt.style!=="17" ).map( punkt => (
            dane.filter( punkt => ( checkSpace(punkt) )).map( punkt => (
                <Punkt
                key={uuid()}
                punkt={punkt}
                nazwa={punkt.name}
                code={punkt.code}
                position={[punkt.lat,punkt.lon]}
                title={punkt.name + punkt.lat +"/" + punkt.lon}
                style={punkt.style}
                rwdir={punkt.rwdir}
                desc={punkt.desc}
                wpts={props.wpts}
                setWpt={props.setWpt}
                /> 
                ))
        }
        </MarkerClusterGroup>
    )
  }

  export default Przestrzen;