import { useRef } from "react";
import { Button } from "@mui/material";
import React from "react";
import { useReactToPrint } from "react-to-print";
import { Npl }  from "./Npl";

export const Wydruk=(props)=>{
    const componentRef = useRef();
   
            

    const handlePrint = useReactToPrint({
        content: () => componentRef.current, 
        documentTitle : "NawigacyjnyPlanLotu",
    });
    return(
    <>
       <div
       style={{ display: "none" }}// This make ComponentToPrint show   only while printing
       > 
       <Npl ref={componentRef} wpts={props.wpts} wind={props.wind} speed={props.speed} pogoda={props.pogoda}/>
      </div>
      <Button className="MuiButton-dialog-modified" style={!props.disabled?{color:'green'}:{margin:'0px'}} disabled={props.disabled} onClick={handlePrint}>Drukuj!</Button>
    </>
    )
}