import L from 'leaflet';

export const vfrpoint = L.divIcon({
    html: `
    <svg width="40" height="32">
        <polygon points="20,13 15,22 25,22" style="fill:transparent;stroke:black;stroke-width:3" />
    </svg>
    `,
    className: "svg-icon",
    iconSize: [32, 40],
    iconAnchor: [20, 14]
  });

  export const ifrpoint = L.divIcon({
    html: `
    <svg width="40" height="32">
        <polygon points="20,13 15,22 25,22" style="fill:black;stroke:black;stroke-width:3" />
    </svg>
    `,
    className: "svg-icon",
    iconSize: [32, 40],
    iconAnchor: [20, 14]
  });

  export const vor = L.divIcon({
    html: `
    <svg width="40" height="32">
        <polygon points="15, 5, 25, 5, 30 ,13, 25,21, 15,21, 10,13, 15,5" style="fill:transparent;stroke:black;stroke-width:2" />
        <circle cx="20" cy="13" r=1 stroke="black" stroke-width="3" fill="black" />
    </svg>
    `,
    className: "svg-icon",
    iconSize: [32, 40],
    iconAnchor: [20, 14]
  });

  export const ndb = L.icon({
    iconUrl: require('./ndb.png'),
    iconRetinaUrl: require('./ndb.png'),
    iconSize: [32, 32],
    iconAnchor: [20, 14]
  });
  
  export   const airportSmall = L.divIcon({
    html: `
    <svg width="40" height="32">
    <circle cx="20" cy="16" r="8" stroke="grey" stroke-width="3" fill="transparent" />
    </svg>
    `,
    className: "svg-icon",
    iconSize: [32, 40],
    iconAnchor: [20, 14]
  });
  
  export const wpt = L.divIcon({
    html: `
    <svg width="20" height="20">
    <circle cx="10" cy="10" r="8" stroke="white" stroke-width="2" fill="red" />
    </svg>
    `,
    className: "svg-icon",
    iconSize: [20, 20],
    //iconAnchor: [20, 14]
  });
  

  export  const airport = L.divIcon({
    html: `
    <svg width="40" height="32">
    <circle cx="20" cy="16" r="8" stroke="grey" stroke-width="3" fill="transparent" />
    <line x1="7" y1="16" x2="12" y2="16" stroke="grey" stroke-width="3"  />
    <line x1="28" y1="16" x2="33" y2="16" stroke="grey" stroke-width="3"  />
    <line x1="20" y1="3" x2="20" y2="8" stroke="grey" stroke-width="3"  />
    <line x1="20" y1="24" x2="20" y2="29" stroke="grey" stroke-width="3"  />
    <line x1="20" y1="24" x2="20" y2="29" stroke="grey" stroke-width="3"  />
    </svg>
    `,
    className: "svg-icon",
    iconSize: [32, 40],
    iconAnchor: [20, 14]
  });
  
  export const airportCtr = L.divIcon({
    html: `
    <svg width="40" height="32">
    <circle cx="20" cy="16" r="8" stroke="grey" stroke-width="3" fill="grey" />
    <line x1="7" y1="16" x2="12" y2="16" stroke="grey" stroke-width="3"  />
    <line x1="28" y1="16" x2="33" y2="16" stroke="grey" stroke-width="3"  />
    <line x1="20" y1="3" x2="20" y2="8" stroke="grey" stroke-width="3"  />
    <line x1="20" y1="24" x2="20" y2="29" stroke="grey" stroke-width="3"  />
    <line x1="20" y1="24" x2="20" y2="29" stroke="grey" stroke-width="3"  />
    <rect x="14" y="14" width="12" height="4"
    style="fill:white;stroke:white;stroke-width:1;fill-opacity:0.1;stroke-opacity:0.9" />
    </svg>
    `,
    className: "svg-icon",
    iconSize: [32, 40],
    iconAnchor: [20, 14]
  });