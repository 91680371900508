import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {ReactComponent as Kreski} from './drag.svg';
import {ReactComponent as Del} from './del.svg';
import uuid from 'react-uuid';
import { calc } from './SelectController';
import { calculate } from './SelectController';

export const przeliczTrase = (trasa) => {
  let wpts=[];
  trasa.map( (wpt,index,array) => {
    wpt.id=uuid();
    if ( index>0 ){
      let start=array[index-1];
      let end=wpt;
      let c=calc(start,end);
      wpt.distance=c[0];wpt.nkdg=c[1];
    }else{
      delete (wpt.nkdg);delete (wpt.distance);
    }
    wpts=[...wpts,wpt];
    return true;
    })
    return wpts;
}

// lista punktow trasy do wyswietenia w postaci draggable
const Lista = (props) => {
  const { wind, speed } = props;


  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(props.wpts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    let wpts=przeliczTrase(items);
    props.setWpt(wpts);
  }

    const oblicz =(name,declination,nkdg,distance) =>{
    const ret=calculate(name,declination,nkdg,distance,wind,speed);
    return (
      distance?
      <div className='obliczenia'>
        <div className='inline course'>{ret.course}</div>
        <div className='inline time'><div className='timefield'>{ret.time}</div><div><div>{ret.distance} NM</div><div>GS:{ret.gs} kts</div></div></div>
      
      </div>
      : <div className='obliczenia'>
      <div className='inline course'>start trasy</div>
      </div>
    )
  }

  function usunClicked(e,index){
    var items = Array.from(props.wpts);
    items.splice(index, 1);
    items=przeliczTrase(items);
    props.setWpt(items);
    
  }
    return(
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="wpts">
            {(provided) => (
              <ul className="wpts" {...provided.droppableProps} ref={provided.innerRef}>
                {//setTotals([0,0]);//zerujemy totalsy przed obliczaniem czasow i odleglosci
                props.wpts.map(({id, name, nkdg, declination,distance}, index, array) => {
                  return (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(provided) => (
                        <li ref={provided.innerRef} {...provided.draggableProps} >
                          <div className="ikonka" {...provided.dragHandleProps}>
                            <Kreski />
                          </div>
                          <div className='pole-nazwy' {...provided.dragHandleProps} >
                            <div className='nazwa-lista'>{ name }</div>
                            {/*
                            <div className='left'><p>{ nkdg?Math.round(nkdg-declination)+'\u00b0'+"/":"START" }{distance?Math.round(distance) + " km":""}</p></div>
                            */}
                            {oblicz(name,index>0?array[index-1].declination:declination,nkdg,distance)}
                          </div>
                          <div className="usun" onClick={(e)=>usunClicked(e,index)} >
                            <Del />
                          </div>
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
    )
}

export default Lista;