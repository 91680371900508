import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { IOSSlider } from './ioslider'



const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
        children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>,
    ){
        return <Slide direction="down" ref={ref} {...props} />;
});
  
  export default function FltProp(props) {
    const [wysokosc, setAltitude] = React.useState(props.alt);
    const [time, setTime] = React.useState(props.time);
    const [open, setOpen] = React.useState(false);
    const marks = [
      {value: 1000},
      {value: 2000},
      {value: 3000},
      {value: 4000},
      {value: 5000},
      {value: 6000},
      {value: 7000},
      {value: 8000},
      {value: 9000},
      ];
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleSlider = (event, newValue) => {
      //console.log(event);
      //setAltitude(newValue) ;
      props.setAltitude(newValue);
    };

    const handleSlider1 = (event, newValue) => {
      //console.log(event);
      setAltitude(newValue) ;
    };

    const handleTimeSlider = (event, newValue) => {
      //console.log(event);
      setTime(newValue) ;
      props.setTime(newValue);
    };
  
  function valuetime(value) {
      return `${value}:00`;
    }

    return (
      <div className='parametry'>
        {/*
        <Button variant="contained" size="small" onClick={handleClickOpen} >
        </Button>
        */}
        <div className='alttimediv'>
        <div className='altdiv'>
        <div className='left'>
        H
        </div>
        <div className='right'>
        <IOSSlider
                   max={9500}
                   min={0}
                   size="small"
                   value={wysokosc}
                   step={100}
                   marks={marks}
                   onChange={handleSlider1}
                   onChangeCommitted={handleSlider}
                   valueLabelDisplay="on"
                   //aria-label="Always visible"
                />
        </div>
        </div>
        <div className='timediv'>
        <div className='left'>
        UTC
        </div>
        <div className='right'>
        <IOSSlider
                   max={24}
                   min={0}
                   size="large"
                   value={time}
                   step={1}
                   marks={true}
                   valueLabelFormat={valuetime}
                   getAriaValueText={valuetime}
                   onChange={handleTimeSlider}
                   valueLabelDisplay="on"
                   //aria-label="Always visible"
                />
        </div>
        </div>
        </div>
        
        <Dialog
          open={open}
          PaperProps={{ sx: { mt: "0px", mx: "0px"} }}
          scroll="paper"
          //fullScreen={fullScreen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
         <DialogTitle>Trasa</DialogTitle>
          <DialogContent>
          <IOSSlider
                   max={9500}
                   min={0}
                   size="small"
                   value={wysokosc}
                   step={100}
                   marks={marks}
                   onChange={handleSlider}
                   valueLabelDisplay="on"
                   //aria-label="Always visible"
                />
          </DialogContent>
          <DialogActions>
            
            <Button onClick={handleClose}>Zamknij</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }