import React from 'react';
import ReactDOM from 'react-dom/client'; //for react 18
//import { render } from 'react-dom'; // for react 17
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));  // for react 18
//const root = document.getElementById('root'); // <- This is the correct method call for React version 17

root.render(  // for 18

  
    <App />
  
);
//reportWebVitals();
