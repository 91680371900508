
import { Marker } from "react-leaflet";
import 'leaflet/dist/leaflet.css'
import L from 'leaflet';
import uuid from 'react-uuid';
import {airport,airportCtr,airportSmall,ifrpoint,vfrpoint,vor,ndb} from './ikony.js';
import { calc } from "./SelectController.js";




delete L.Icon.Default.prototype._getIconUrl;

/*
function getTanDeg(deg) {
  const rad = (deg * Math.PI) / 180;
  return Math.tan(rad);
}
*/

export default function Punkt(props) {
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

function PointClicked(){
   const wpt={
      id : uuid(),
      name: props.code,
      lon:props.position[1],
      lat:props.position[0],
      desc:'',
      freq:props.punkt.freq,
      rwdir:props.punkt.rwdir,
      rwlen:props.punkt.rwlen,
      elev:Math.round(props.punkt.elev*3.2808399),
      geolocated:true
    }
    //console.log(props.wpts);
    if ( props.wpts.length > 0 && props.wpts[props.wpts.length-1].name === wpt.name) // usuwamy doubleclick
      return;
    
    //jezeli to juz nie poierwszy punkt, to obliczamy odleglosc i kurs
    if ( props.wpts.length>0 ){
      let prevWpt=props.wpts[props.wpts.length-1];
      let c=calc(prevWpt, wpt);
      wpt.distance=c[0];
      wpt.nkdg=c[1];
    }
    props.setWpt( [...props.wpts, wpt])
    //console.log(props.wpts);
  
}

function caseIcon( what ){
  switch(what) {
    case '1':
      return vfrpoint;
    case '3':
      return airportSmall;
    case '2':
      return airport;
    case '5':
      return airportCtr;
    case '17':
      return ifrpoint;
    case 100:
    case 101:
    case 102:
      return vor;
    case 103:
      return ndb;
    default: return airportSmall;
  }

}

  return (
    <div>
      <Marker position={props.position}
      title={props.title}
      key={props.klucz}
      id={props.klucz}
      icon={caseIcon(props.style)}
      eventHandlers={{
        click: (e) => {
          PointClicked();
        },
      }}
      >
       
    </Marker>
    </div>
  );
}